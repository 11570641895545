<template>
  <LoginCard>
    <template slot="header">Logg inn</template>

    <template slot="body">
      <form @submit="logIn" class="signin-form" novalidate="true">
        <label for="user">Brukernavn</label>
        <input
          class="input"
          :class="{ 'error-input': errors.userName }"
          type="email"
          id="user"
          name="user"
          autocomplete="username"
          v-model.trim="userName"
        />
        <span v-if="errors.userName" class="error-text">{{ errors.userName }}</span>
        <label for="password">Passord</label>
        <input
          class="input"
          :class="{ 'error-input': errors.password }"
          type="password"
          id="password"
          name="password"
          autocomplete="current-password"
          v-model.trim="password"
        />
        <span v-if="errors.password" class="error-text">{{ errors.password }}</span>
        <MainButton @click="logIn">{{ buttonText }}</MainButton>
        <router-link :to="{ name: 'retrievePassword' }" class="link">Glemt passord?</router-link>
      </form>
    </template>
  </LoginCard>
</template>

<script>
import LoginCard from '@/components/elements/LoginCard.vue';
import MainButton from '@/components/elements/MainButton.vue';

export default {
  components: {
    LoginCard,
    MainButton,
  },
  data() {
    return {
      userName: '',
      password: '',
      buttonText: 'Logg inn',
      errors: {},
    };
  },
  methods: {
    async logIn(e) {
      e.preventDefault();

      // validate inputs
      this.errors = {};
      if (!this.validateRequired(this.userName)) {
        this.errors.userName = 'Vi må ha en bruker';
        return false;
      }
      if (!this.validateRequired(this.password)) {
        this.errors.password = 'Har du ett passord kanskje?';
        return false;
      }

      // set button loading state
      this.buttonText = 'Sjekker...';

      // try to log in
      const loggedIn = await this.$store.dispatch('user/signIn', {
        userName: this.userName,
        password: this.password,
      });

      if (loggedIn) {
        this.buttonText = 'Okay!';
        const lastRoute = this.$route.query.redirect || '/tilvalg';
        return this.$router.push(lastRoute);
      }

      if (!loggedIn) {
        this.$notify({
          group: 'user',
          type: 'error',
          title: 'Vi kunne ikke logge deg inn',
          text: this.$store.state.user.error,
        });
      }

      this.buttonText = 'Logg inn';
      this.clearInputs();
      return false;
    },

    validateRequired(value = '') {
      return !!value;
    },

    clearInputs() {
      this.userName = '';
      this.password = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 200px;
  margin-bottom: $spacing-150;
}

.link {
  color: $color-gray-400;
  margin-top: $spacing-100;
  text-align: center;
  font-size: $font-size--2;
}

.signin-form {
  display: flex;
  flex-direction: column;
}

.error-input {
  border-color: $color-red-300;
}

.error-text {
  font-size: $font-size--2;
  margin-top: -$spacing-100;
  margin-bottom: $spacing-050;
  color: $color-red-600;
}
</style>
